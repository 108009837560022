/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useMemo } from 'react';
import { KTIcon, toAbsoluteUrl } from '../../../helpers';

type Props = {
  className: string;
  data: {
    id: string;
    name: string;
    productData: {
      reference: string;
      ean: string;
      mpn: string;
      brand: string;
      categoryId: string;
      description: string;
      imageUrl: string;
    };
    userPrices: {
      minPrice: number;
      maxPrice: number;
      msrp: number;
    };
    listingData: {
      avgPrice: number;
      minPriceSellers: number;
      maxPriceSellers: number;
      countSellers: number;
      countSellersBelowMinPrice: number;
      countSellersWithoutStock: number;
      sellers?: Array<{
        seller: string;
        price: number;
        channel: string;
        idProduct: string;
        inStock: boolean;
        idData: string;
        priceSale: number;
        url: string;
        timestamp: string;
        actualPrice: number;
      }>;
    };
  };
};

const TableSingleProduct: React.FC<Props> = ({ className, data }) => {
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const processedData = useMemo(() => {
    const sellers = data.listingData.sellers || [];
    const dataWithCurrentPrice = sellers.map(item => ({
      ...item,
      currentPrice: item.priceSale || item.price,
    }));

    if (!sortColumn) return dataWithCurrentPrice;

    return [...dataWithCurrentPrice].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (a[sortColumn] > b[sortColumn]) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [data, sortColumn, sortDirection]);

  const handleSort = (column: string) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const getSortIcon = (column: string) => {
    if (sortColumn !== column) {
      return null; // No mostrar icono si no es la columna de ordenamiento actual
    }
    return sortDirection === 'asc' ? '↑' : '↓'; // Cambia por iconos si los tienes
  };

  const convertToCSV = objArray => {
    const array =
      typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index in array[i]) {
        if (line !== '') line += ',';

        line += array[i][index];
      }

      str += line + '\r\n';
    }

    return str;
  };

  const downloadCSV = () => {
    const csv = convertToCSV(processedData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'data.csv';
    link.click();
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">{data.name}</span>
          <span className="text-muted mt-1 fw-semibold fs-7">
            Detailed view of the product
          </span>
        </h3>
        <div className="card-toolbar">
          {/* begin::Menu */}
          <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <KTIcon iconName="category" className="fs-2" />
          </button>
          {/* begin::Menu 2 */}
          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-200px"
            data-kt-menu="true"
          >
            {/* begin::Menu item */}
            <div className="menu-item px-3">
              <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">
                Actions
              </div>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu separator */}
            <div className="separator mb-3 opacity-75"></div>
            {/* end::Menu separator */}
            {/* begin::Menu item */}
            <div className="menu-item px-3">
              <a href="#" className="menu-link px-3">
                New Support Ticket
              </a>
            </div>
            {/* end::Menu item */}

            {/* begin::Menu item */}
            <div className="menu-item px-3">
              <div className="menu-content px-3 py-3">
                <a
                  className="btn btn-primary btn-sm px-4"
                  onClick={downloadCSV}
                >
                  Download CSV
                </a>
              </div>
            </div>
            {/* end::Menu item */}
          </div>
          {/* end::Menu 2 */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bold text-muted bg-light">
                <th
                  className="ps-4 min-w-300px rounded-start"
                  onClick={() => handleSort('seller')}
                >
                  Seller {getSortIcon('seller')}
                </th>
                <th
                  className="min-w-125px"
                  onClick={() => handleSort('currentPrice')}
                >
                  Price {getSortIcon('currentPrice')}
                </th>
                <th className="min-w-100px" onClick={() => handleSort('stock')}>
                  Stock status {getSortIcon('stock')}
                </th>
                <th
                  className="min-w-50px"
                  onClick={() => handleSort('currentPrice')}
                >
                  In alarm
                </th>
                <th
                  className="min-w-50px"
                  onClick={() => handleSort('timestamp')}
                >
                  Last time checked {getSortIcon('timestamp')}
                </th>
                <th className="min-w-150px text-end rounded-end"></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {processedData.map((item, index) => (
                <tr key={item.idData}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50px me-5">
                        <span className="symbol-label bg-light">
                          <img
                            src={toAbsoluteUrl(
                              '/media/svg/avatars/001-boy.svg',
                            )}
                            className="h-75 align-self-end"
                            alt=""
                          />
                        </span>
                      </div>
                      <div className="d-flex justify-content-start flex-column">
                        <a
                          href={`/product/${item.idProduct}`}
                          className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                        >
                          {item.seller.charAt(0).toUpperCase() +
                            item.seller.slice(1)}
                        </a>
                        {item.channel !== item.seller && (
                          <span className="text-muted fw-semibold d-block fs-7">
                            via: {item.channel}
                          </span>
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    <a
                      href={`/product/${item.idProduct}`}
                      className="text-dark text-hover-primary d-block mb-1 fs-6"
                    >
                      {item.currentPrice} €
                    </a>
                    {item.priceSale !== 0 && (
                      <span className="text-muted fw-semibold d-block fs-7">
                        PVP: {item.price} €
                      </span>
                    )}
                  </td>
                  <td>
                    {item.inStock ? (
                      <span className="badge badge-light-success">
                        In Stock
                      </span>
                    ) : (
                      <span className="badge badge-light-danger">
                        Out of stock
                      </span>
                    )}
                  </td>
                  <td>
                    {item.currentPrice < data.userPrices.minPrice ? (
                      <span className="badge badge-light-danger">Yes</span>
                    ) : (
                      <span className="badge">-</span>
                    )}
                  </td>
                  <td>{new Date(item.timestamp).toLocaleString()}</td>
                  <td className="text-end">
                    <a
                      href={`${item.url}`}
                      target="_blank"
                      className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                      rel="noreferrer"
                    >
                      Product URL
                    </a>
                    <a
                      href="#"
                      className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4"
                    >
                      Edit
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { TableSingleProduct };
