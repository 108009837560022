/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { AsideMenuItem } from './AsideMenuItem';

export function AsideMenuMain() {
  const intl = useIntl();

  return (
    <>
      <AsideMenuItem
        to="/dashboard"
        icon="element-11"
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      />
      {/* <AsideMenuItem to="/builder" icon="switch" title="Layout Builder" /> */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Data analysis
          </span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to="/products"
        title={intl.formatMessage({ id: 'PRODUCTS' })}
        icon="gift"
      >
        <AsideMenuItem
          to="/products/all-products"
          title="All Products"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/products/settings"
          title="Settings"
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to="/merchants" title="Merchants" icon="people">
        <AsideMenuItem
          to="/merchants/sellers"
          title="All Sellers"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/merchants/marketplaces"
          title="Marketplaces"
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to="/alerts" title="Alerts" icon="information-3">
        <AsideMenuItem
          to="/alerts/out-of-stock"
          title="Out of stock"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/alerts/price-infractions"
          title="Price infractions"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/alerts/settings"
          title="Settings"
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub title="Helpers" to="#" icon="trash">
        <AsideMenuItemWithSub to="/crafted/pages" title="Pages" icon="gift">
          <AsideMenuItemWithSub
            to="/crafted/pages/profile"
            title="Profile"
            hasBullet={true}
          >
            <AsideMenuItem
              to="/crafted/pages/profile/overview"
              title="Overview"
              hasBullet={true}
            />
            <AsideMenuItem
              to="/crafted/pages/profile/projects"
              title="Projects"
              hasBullet={true}
            />
            <AsideMenuItem
              to="/crafted/pages/profile/campaigns"
              title="Campaigns"
              hasBullet={true}
            />
            <AsideMenuItem
              to="/crafted/pages/profile/documents"
              title="Documents"
              hasBullet={true}
            />
            <AsideMenuItem
              to="/crafted/pages/profile/connections"
              title="Connections"
              hasBullet={true}
            />
          </AsideMenuItemWithSub>

          <AsideMenuItemWithSub
            to="/crafted/pages/wizards"
            title="Wizards"
            hasBullet={true}
          >
            <AsideMenuItem
              to="/crafted/pages/wizards/horizontal"
              title="Horizontal"
              hasBullet={true}
            />
            <AsideMenuItem
              to="/crafted/pages/wizards/vertical"
              title="Vertical"
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to="/error" title="Errors" icon="cross-circle">
          <AsideMenuItem to="/error/404" title="Error 404" hasBullet={true} />
          <AsideMenuItem to="/error/500" title="Error 500" hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub
          to="/crafted/widgets"
          title="Widgets"
          icon="element-plus"
        >
          <AsideMenuItem
            to="/crafted/widgets/lists"
            title="Lists"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/crafted/widgets/statistics"
            title="Statistics"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/crafted/widgets/charts"
            title="Charts"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/crafted/widgets/mixed"
            title="Mixed"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/crafted/widgets/tables"
            title="Tables"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/crafted/widgets/feeds"
            title="Feeds"
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Product management
          </span>
        </div>
      </div>
      <AsideMenuItem
        to="/manage/add-product"
        title="Add product"
        hasBullet={true}
      />
      <AsideMenuItem
        to="/manage/edit-product"
        title="Edit product"
        hasBullet={true}
      />
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            My account
          </span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to="/crafted/accounts"
        title="Accounts"
        icon="profile-circle"
      >
        <AsideMenuItem
          to="/crafted/account/overview"
          title="Overview"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/crafted/account/overview"
          title="Billing"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/crafted/account/settings"
          title="Settings"
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <div className="menu-item">
        <div className="menu-content">
          <div className="separator mx-1 my-4"></div>
        </div>
      </div>
      <div className="menu-item">
        <AsideMenuItem to="/support" title="Support" icon="question-2" />
      </div>
    </>
  );
}
