import { useEffect, useState } from 'react'
import { TableSingleProduct } from '../../../../_metronic/partials/widgets'
import { useParams, useLocation } from 'react-router-dom'
import { StatisticsWidget5 } from '../../../../_metronic/partials/widgets'
import { backendApiCall } from '../../../helpers/axios'

// Definir la interfaz para los datos del estado
interface LocationState {
  data: {
    idProduct: string
    name: string
    reference: string
    priceMin: number
  }
}

interface ProductData {
  id: string
  name: string
  productData: {
    reference: string
    ean: string
    mpn: string
    brand: string
    categoryId: string
    description: string
    imageUrl: string
  }
  userPrices: {
    minPrice: number
    maxPrice: number
    msrp: number
  }
  listingData: {
    avgPrice: number
    minPriceSellers: number
    maxPriceSellers: number
    countSellers: number
    countSellersBelowMinPrice: number
    countSellersWithoutStock: number
    sellers?: Array<{
      seller: string
      price: number
      channel: string
      idProduct: string
      inStock: boolean
      idData: string
      priceSale: number
      url: string
      timestamp: string
      actualPrice: number
    }>
  }
}

export function ProductDetailPage() {
  const [apiData, setApiData] = useState<ProductData>()
  const { id } = useParams()
  const location = useLocation()
  const passedData = (location.state as LocationState)?.data

  useEffect(() => {
    backendApiCall(`/product/${id}`, 'GET')
      .then(response => {
        setApiData(response.data)
      })
      .catch(error => {
        console.error('Error al obtener datos de la API', error)
      })
  }, [id])

  if (!apiData) {
    return <div>Loading...</div>
  }

  // return <TableSingleProduct className="mb-5 mb-xl-8" data={apiData} />;
  return (
    <>
      <div className="row g-5 g-xl-8">
        <div className="col-xl-3">
          <StatisticsWidget5
            className="card-xl-stretch mb-xl-8"
            svgIcon="chart-simple"
            color="white"
            iconColor="primary"
            title={apiData.listingData.countSellers.toString()}
            description="Number of sellers"
          />
        </div>

        <div className="col-xl-3">
          <StatisticsWidget5
            className="card-xl-stretch mb-xl-8"
            svgIcon="cheque"
            color="dark"
            iconColor="white"
            title={apiData.listingData.avgPrice.toString() + ' €'}
            titleColor="white"
            description="Average price"
            descriptionColor="white"
          />
        </div>

        <div className="col-xl-3">
          <StatisticsWidget5
            className="card-xl-stretch mb-xl-8"
            svgIcon="briefcase"
            color="warning"
            iconColor="white"
            title={apiData.listingData.minPriceSellers.toString() + ' €'}
            titleColor="white"
            description="Minimum price"
            descriptionColor="white"
          />
        </div>

        <div className="col-xl-3">
          <StatisticsWidget5
            className="card-xl-stretch mb-5 mb-xl-8"
            svgIcon="chart-pie-simple"
            color="info"
            iconColor="white"
            title={apiData.listingData.countSellersBelowMinPrice.toString()}
            titleColor="white"
            description="Price infractions"
            descriptionColor="white"
          />
        </div>
      </div>
      <TableSingleProduct className="mb-5 mb-xl-8" data={apiData} />
    </>
  )
}
