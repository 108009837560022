/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { KTIcon, toAbsoluteUrl } from '../../../helpers';
import { useNavigate } from 'react-router-dom';

type Product = {
  id: string;
  name: string;
  productData: {
    reference: string;
    ean: string;
    mpn: string;
    brand: string;
    categoryId: string;
    description: string;
    imageUrl: string;
  };
  userPrices: {
    minPrice: number;
    maxPrice: number;
    msrp: number;
  };
  listingData: {
    avgPrice: number;
    minPriceSellers: number;
    maxPriceSellers: number;
    countSellers: number;
    countSellersBelowMinPrice: number;
    countSellersWithoutStock: number;
    sellers?: Array<{
      seller: string;
      price: number;
      channel: string;
      idProduct: string;
      inStock: boolean;
      idData: string;
      priceSale: number;
      url: string;
      timestamp: string;
      actualPrice: number;
    }>;
  };
};

type Props = {
  className: string;
  data: Array<Product>;
};

const TableAllProducts: React.FC<Props> = ({ className, data }) => {
  const navigate = useNavigate();

  const handleNavigate = item => {
    navigate(`/product/${item.id}`, { state: { data: item } });
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Product list</span>
          <span className="text-muted mt-1 fw-semibold fs-7">
            Check all your products
          </span>
        </h3>
        <div className="card-toolbar">
          {/* begin::Menu */}
          <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <KTIcon iconName="category" className="fs-2" />
          </button>
          {/* begin::Menu 2 */}
          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-200px"
            data-kt-menu="true"
          >
            {/* begin::Menu item */}
            <div className="menu-item px-3">
              <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">
                Quick Actions
              </div>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu separator */}
            <div className="separator mb-3 opacity-75"></div>
            {/* end::Menu separator */}
            {/* begin::Menu item */}
            <div className="menu-item px-3">
              <a href="#" className="menu-link px-3">
                New Product
              </a>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu item */}
            <div className="menu-item px-3">
              <a href="#" className="menu-link px-3">
                Manage Products
              </a>
            </div>
            {/* end::Menu item */}
            {/* begin::Menu separator */}
            <div className="separator mt-3 opacity-75"></div>
            {/* end::Menu separator */}
            {/* begin::Menu item */}
            <div className="menu-item px-3">
              <div className="menu-content px-3 py-3">
                <a className="btn btn-primary btn-sm px-4" href="#">
                  Download CSV
                </a>
              </div>
            </div>
            {/* end::Menu item */}
          </div>
          {/* end::Menu 2 */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bold text-muted bg-light">
                <th className="ps-4 min-w-300px rounded-start">Name</th>
                {/* <th className="min-w-150px">Reference</th> */}
                <th className="min-w-70px text-center">MSRP</th>
                <th className="min-w-50px text-center">
                  User <br></br>Low
                </th>
                <th className="min-w-50px text-center">
                  Market <br></br>avg
                </th>
                <th className="min-w-50px text-center">
                  Market <br></br>low
                </th>
                <th className="min-w-50px text-center">
                  Sellers <br></br>no stock
                </th>
                <th className="min-w-50px text-center">Infractions</th>
                <th className="min-w-100px text-end rounded-end"></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50px me-5">
                        <span className="symbol-label bg-white">
                          {item.productData.imageUrl ? (
                            <img
                              src={item.productData.imageUrl}
                              className="h-100 bg-light"
                              alt=""
                            />
                          ) : (
                            <img
                              src={toAbsoluteUrl(
                                '/media/svg/avatars/001-boy.svg',
                              )}
                              className="h-75 align-self-end"
                              alt=""
                            />
                          )}
                        </span>
                      </div>
                      <div className="d-flex fw-bold text-hover-primary justify-content-start flex-column fs-6">
                        <div
                          onClick={() => handleNavigate(item)}
                          style={{ cursor: 'pointer' }}
                        >
                          {item.name}
                        </div>
                        <span className="text-muted fw-semibold text-muted d-block fs-8">
                          {item.productData.reference}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <a
                      href={`/product/${item.id}`}
                      className="text-dark text-hover-primary d-block fs-7 text-center"
                    >
                      {item.userPrices.msrp} €
                    </a>
                  </td>
                  <td className="text-center">
                    <a
                      href={`/product/${item.id}`}
                      className="text-dark text-hover-primary d-block fs-7 text-center"
                    >
                      {item.userPrices.minPrice} €
                    </a>
                  </td>

                  <td className="text-center">
                    <a
                      href={`/product/${item.id}`}
                      className="text-dark text-hover-primary d-block fs-7 text-center"
                    >
                      {item.listingData.avgPrice} €
                    </a>
                  </td>

                  <td className="text-center">
                    {item.listingData.minPriceSellers <
                    item.userPrices.minPrice ? (
                      <span className="badge badge-light-danger fs-7 text-center">
                        {item.listingData.minPriceSellers} €
                      </span>
                    ) : (
                      <span className="badge badge-light-success fs-7 text-center">
                        {item.listingData.minPriceSellers} €
                      </span>
                    )}
                  </td>

                  <td className="text-center">
                    {item.listingData.countSellersWithoutStock !== 0 ? (
                      <span className="badge badge-light-danger fs-7 text-center">
                        {item.listingData.countSellersWithoutStock}
                      </span>
                    ) : (
                      <span className="badge badge-light-success fs-7 text-center">
                        0
                      </span>
                    )}
                  </td>

                  <td className="text-center">
                    {item.listingData.countSellersBelowMinPrice !== 0 ? (
                      <span className="badge badge-light-danger fs-7 text-center">
                        {item.listingData.countSellersBelowMinPrice}
                      </span>
                    ) : (
                      <span className="badge badge-light-success fs-7 text-center">
                        0
                      </span>
                    )}
                  </td>

                  <td className="text-end">
                    <a
                      href={`/product/${item.id}`}
                      className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
                    >
                      View
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { TableAllProducts };
