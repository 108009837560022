import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import {
  ListsWidget1,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  MixedWidget10,
  MixedWidget11,
  MixedWidget2,
  StatisticsWidget5,
  StatisticsWidget4,
  TablesWidget10,
  TablesWidget5,
  StatisticsWidget6,
  StatisticsWidget3,
} from '../../../_metronic/partials/widgets';
import { ListLatestOutOfStock } from '../../../_metronic/partials/widgets/lists/ListLatestOutOfStock';
import { TableNewPriceInfractions } from '../../../_metronic/partials/widgets/tables/TableNewPriceInfractions';
import { MixedHistorialCountInfractions } from '../../../_metronic/partials/widgets/mixed/MixedHistorialCountInfractions';
import { MixedHistorialCountOutStock } from '../../../_metronic/partials/widgets/mixed/MixedHistorialCountOutStock';

const DashboardPage = () => (
  <>
    {/* begin::Row */}
    <div className="row g-5 g-xl-8">
      <div className="col-xl-4">
        <StatisticsWidget5
          className="card-xl-stretch mb-xl-8"
          svgIcon="chart-simple"
          color="white"
          iconColor="primary"
          title="500M$"
          description="SAP UI Progress"
        />
      </div>

      <div className="col-xl-4">
        <StatisticsWidget5
          className="card-xl-stretch mb-xl-8"
          svgIcon="cheque"
          color="dark"
          iconColor="white"
          title="+3000"
          titleColor="white"
          description="New Customers"
          descriptionColor="white"
        />
      </div>

      <div className="col-xl-4">
        <StatisticsWidget5
          className="card-xl-stretch mb-xl-8"
          svgIcon="briefcase"
          color="warning"
          iconColor="white"
          title="$50,000"
          titleColor="white"
          description="Milestone Reached"
          descriptionColor="white"
        />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className="row g-5 g-xl-8">
      <div className="col-xl-4">
        <StatisticsWidget5
          className="card-xl-stretch mb-xl-8"
          svgIcon="chart-simple"
          color="white"
          iconColor="primary"
          title="500M$"
          description="SAP UI Progress"
        />
      </div>

      <div className="col-xl-4">
        <StatisticsWidget5
          className="card-xl-stretch mb-xl-8"
          svgIcon="cheque"
          color="dark"
          iconColor="white"
          title="+3000"
          titleColor="white"
          description="New Customers"
          descriptionColor="white"
        />
      </div>

      <div className="col-xl-4">
        <StatisticsWidget5
          className="card-xl-stretch mb-xl-8"
          svgIcon="briefcase"
          color="warning"
          iconColor="white"
          title="$50,000"
          titleColor="white"
          description="Milestone Reached"
          descriptionColor="white"
        />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className="row g-5 g-xl-8">
      {/* begin::Col */}
      <div className="col-xl-4">
        <ListLatestOutOfStock className="card-xl-stretch mb-xl-8" />
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className="col-xl-8">
        <TableNewPriceInfractions className="card-xl-stretch mb-5 mb-xl-8" />
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className="row gy-5 g-xl-8">
      {/* <div className="col-xxl-4">
        <MixedWidget2
          className="card-xl-stretch mb-xl-8"
          chartColor="danger"
          chartHeight="200px"
          strokeColor="#cb1e46"
        />
      </div> */}
      {/* <div className="col-xxl-4">
        <ListsWidget5 className="card-xxl-stretch" />
      </div> */}
      <div className="col-xxl-4">
        <MixedHistorialCountInfractions
          className="card-xxl-stretch-50 mb-5 mb-xl-8"
          chartColor="primary"
          chartHeight="150px"
        />
        <MixedHistorialCountOutStock
          className="card-xxl-stretch-50 mb-5 mb-xl-8"
          chartColor="primary"
          chartHeight="175px"
        />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    {/* <div className="row gy-5 gx-xl-8">
      <div className="col-xxl-4">
        <ListsWidget3 className="card-xxl-stretch mb-xl-3" />
      </div>
      <div className="col-xl-8">
        <TablesWidget10 className="card-xxl-stretch mb-5 mb-xl-8" />
      </div>
    </div> */}
    {/* end::Row */}

    {/* begin::Row */}
    {/* <div className="row gy-5 g-xl-8">
      <div className="col-xl-4">
        <ListsWidget2 className="card-xl-stretch mb-xl-8" />
      </div>
      <div className="col-xl-4">
        <ListsWidget6 className="card-xl-stretch mb-xl-8" />
      </div>
      <div className="col-xl-4">
        <ListsWidget4 className="card-xl-stretch mb-5 mb-xl-8" items={5} />
      </div>
    </div> */}
    {/* end::Row */}
  </>
);

const DashboardWrapper = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      </PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
