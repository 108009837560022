import axios, { Method } from 'axios'

export const backendApiCall = async (
  path: string,
  method: Method,
  body?: string,
) => {
  const baseURL = process.env.REACT_APP_PRIDOG_BACKEND_API_BASE

  const axiosInstance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_PRIDOG_BACKEND_SECRET,
    },
  })

  try {
    const response = await axiosInstance.request({
      url: path,
      method,
      data: body,
    })
    return response
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Error de Axios:', error.response?.data || error.message)
    } else {
      console.error('Error inesperado:', error)
    }
    throw error
  }
}

export default backendApiCall
